<template>
  <div>
    <ManifestSearchBar @edit="edit" @search="searchList" :multiple-selection="multipleSelection"></ManifestSearchBar>
    <ShManifestTable
        :curr-page="currPage"
        :page-size="pageSize"
        :total-count="totalCount"
        :total-page="totalPage"
        :table-header="tableHeaders"
        :table-data="tableData"
        :type="search_completionstatus"
        @fromChild="getSelection"
        @refresh="searchList"
        @edit="edit"
        @nextPage="nextPage"
        @pageSizeChange="pageSizeChange"
        ref="ShManifestTable">
    </ShManifestTable>
  </div>
</template>

<script>
import mixin from '@/mixin/manifest/sh/manifestTable'

export default {
  mixins: [mixin],
  name: "DraftTable",
  data() {
    return {
      search_completionstatus: 0, //搜索的舱单状态
    }
  },
}
</script>

<style lang="stylus" scoped>

</style>
